import React from 'react';
import PropTypes from 'prop-types';
import NavMenuTemplate from '../NavMenuTemplate';
import InfoBodyTemplate from '../InfoBodyTemplate';

function InfoLandingTemplate(props) {
  return (
    <>
      {/* <NavMenuTemplate
        {...props}
      /> */}
      <InfoBodyTemplate
        {...props}
      />
    </>
  )
}

InfoLandingTemplate.propTypes = {}

export default InfoLandingTemplate
