import React, { Component } from "react";
import API from "API";
import styled from "styled-components";
import { Row, Button, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ReactComponent as SearchIcon } from "images/icons/Search.svg";
import { ReactComponent as SpeakerIcon } from "images/icons/Feedback.svg";
import { ReactComponent as NotificationIcon } from "images/icons/Notifications.svg";
import { BrowserView, MobileOnlyView } from "react-device-detect";
import Dropzone from "react-dropzone";
import axios from "axios";
import { Avatar } from "Stories/Avatars.stories.js";
import moment from "moment";

class UserFeed extends Component {
  state = {
    feed: [],
    profileComplete: false,
    profilePhoto: "",
    message: "",
  };

  componentDidMount() {
    API.get(`/UserFeeds?filter={"order": "createdAt DESC"}`).then(
      (response) => {
        console.log("Feed Data: ", response.data);
        this.setState({
          feed: response.data,
        });
      }
    );

    // var myInt = setInterval(() => {
    //   API.get(`/UserProfiles/${this.props.userToken.userId}`).then(response => {
    //     this.setState({
    //       profileComplete: response.data.profileComplete
    //     });
    //   });
    // }, 2000);
    // this.setState({
    //   myInt: myInt
    // });
  }

  handleChange = (e) => {
    let { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  uploadFile = (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      let formData = new FormData();
      formData.append("newFormData", file);
      axios
        .post(
          "https://my.gogig.com:4000/api/Attachments/gogig-v3/upload",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          API.patch(`/UserProfiles/${this.props.userToken.userId}`, {
            profilePhoto:
              "https://my.gogig.com:4000/api/Attachments/gogig-v3/download/" +
              response.data.result.files.newFormData[0].name,
          });
          this.setState({
            profilePhoto:
              "https://my.gogig.com:4000/api/Attachments/gogig-v3/download/" +
              response.data.result.files.newFormData[0].name,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    });
  };

  componentDidUpdate() {
    // API.get(`/UserProfiles/${this.props.userToken.userId}`).then((response) => {
    //   this.setState({
    //     feed: response.data,
    //     profileComplete: response.data.profileComplete,
    //   });
    // });
  }

  handleSubmit = () => {
    API.post(`/UserFeeds`, {
      message: this.state.message,
      attachment: this.state.profilePhoto,
      userId: this.props.userToken.userId,
    }).then(() => {
      this.setState({
        message: "",
        profilePhoto: "",
      });
      API.get(`/UserFeeds?filter={"order": "createdAt DESC"}`).then(
        (response) => {
          this.setState({
            feed: response.data,
          });
        }
      );
    });
  };

  componentWillUnmount() {
    clearInterval(this.state.myInt);
  }

  renderStatusMessage() {
    return this.props.profileComplete ? (
      <CompleteProfileCard>
        <CompleteProfileCardTitleContainer>
          <SpeakerIcon />
          <CompleteProfileCardTitle>You're all set!</CompleteProfileCardTitle>
        </CompleteProfileCardTitleContainer>
        <CompleteProfileCardDescription>
          You've succesfully completed your profile! Check your culture report
          under Personality Snapshot to learn more about how hiring managers
          will see you.
        </CompleteProfileCardDescription>
        <Row>
          <Col>
            <Link to="/profile">
              <Button className="float-right">View Profile</Button>
            </Link>
          </Col>
        </Row>
      </CompleteProfileCard>
    ) : (
      <CompleteProfileCard>
        <CompleteProfileCardTitleContainer>
          <SearchIcon />

          <CompleteProfileCardTitle>
            Your profile is not discoverable to Hiring Companies within Take
            Root In Amarillo | Job Up
          </CompleteProfileCardTitle>
        </CompleteProfileCardTitleContainer>
        <CompleteProfileCardDescription>
          Your profile is not complete. In order for you to become discoverable
          to Hiring Companies in Take Root In Amarillo | Job Up and view your
          Job Up Communication Style Report, you must complete your profile.
        </CompleteProfileCardDescription>
        <Link to="/profile?onboard=true">
          <Button
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: 0,
            }}
          >
            Complete Profile
          </Button>
        </Link>
      </CompleteProfileCard>
    );
  }

  render() {
    return (
      <div>
        {!this.props.recruiterMode && this.renderStatusMessage()}

        <CompleteProfileCard style={{ marginTop: 20 }}>
          <CompleteProfileCardTitleContainer>
            <NotificationIcon />
            <CompleteProfileCardTitle>
              Enable Notifications
            </CompleteProfileCardTitle>
          </CompleteProfileCardTitleContainer>
          <CompleteProfileCardDescription>
            We don't communicate a lot to you, but when we do, it's important
            because you have a match!
          </CompleteProfileCardDescription>
          <Row>
            <Col>
              <Link to="/settings/notifications">
                <Button className="float-right">Go To Notifications</Button>
              </Link>
            </Col>
          </Row>
        </CompleteProfileCard>

        {/* {this.props.location.pathname !== "/" && (
          <div
            style={{
              width: "100%",
              display: "flex",
              position: "relative",
              alignItems: "flex-start",
              flexDirection: "column",
              background: "white",
              marginBottom: 20,
              padding: 15,
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  flex: "1",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <textarea
                  onChange={this.handleChange}
                  value={this.state.message}
                  name="message"
                  placeholder="What would you like to post?"
                  style={{
                    width: "100%",
                    outline: "none",
                    border: "none",
                    resize: "none",
                  }}
                ></textarea>
              </div>
              {this.state.profilePhoto === "" ? (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    height: "100px",
                    alignItems: "flex-start",
                    flex: "0 0 auto",
                  }}
                >
                  <Dropzone onDrop={this.uploadFile}>
                    {({ getRootProps, getInputProps }) => (
                      <StyledSection>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <p style={{ margin: 0 }} className="text-muted">
                            Add Attachment
                          </p>
                        </div>
                      </StyledSection>
                    )}
                  </Dropzone>
                </div>
              ) : (
                <div
                  style={{
                    width: "100%",
                    height: "auto",
                    alignItems: "flex-start",
                    flex: "0 0 auto",
                  }}
                >
                  <div>
                    <p
                      onClick={() => this.setState({ profilePhoto: "" })}
                      style={{ textAlign: "right", cursor: "pointer" }}
                    >
                      Remove
                    </p>
                  </div>
                  <ProfilePhoto src={this.state.profilePhoto} />
                </div>
              )}

              <div
                style={{
                  marginTop: 10,
                  display: "flex",
                  width: "100%",
                  alignItems: "flex-end",
                  flex: "0 0 auto",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Button onClick={this.handleSubmit} className="float-right">
                  Post
                </Button>
              </div>
            </div>
          </div>
        )} */}

        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage src={require("images/logo/amarillo-logo.png")} />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>
                Welcome To Take Root In Amarillo
              </NewsFeedCardName>
              <NewsFeedCardJob>System</NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>August 21 at 2:26 pm</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              In Amarillo, Texas, the possibilities are as wide open as the
              enormous blue sky. Filled with independent thinkers and
              bootstrapping entrepreneurs, this is a community where everyone is
              your neighbor. Everyone is rooting for you. Everyone is invested
              in your success.
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
          <NewsFeedVideoContainer>
            <video controls preload="metadata">
              <source type="video/mp4" src="/videos/video-1.mp4#t=0.1" />
            </video>
          </NewsFeedVideoContainer>
        </NewsFeedCard>

        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage
              style={{
                width: 45,
                height: 40,
              }}
              src={require("images/photos/KevinCarter.jpg")}
            />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Kevin Carter</NewsFeedCardName>
              <NewsFeedCardJob>President & CEO, Amarillo EDC</NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>August 22 at 3:10 pm</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              Welcome to open spaces and endless opportunities – welcome to
              Amarillo! <br />
              <br />
              I’m Kevin Carter, President and CEO of the Amarillo Economic
              Development Corporation. In Amarillo, you’ll encounter the
              kindness of our community, visit numerous small businesses, grab a
              bite at any of our amazing & delicious local restaurants, and
              explore our unique area attractions - such as the world-famous
              Palo Duro Canyon, historic Route 66, and more. <br />
              <br />
              Not only are we a city where everyone is your neighbor but, we
              have a friendly business environment that works to help you grow
              and thrive. We are proud of our diverse population, robust job
              market, and a combination of city life, hospitality, and outdoor
              adventure. In Amarillo, we enjoy a level of lifestyle and
              affordability other cities can’t match. We think you’ll find
              Amarillo is a great city to take root!
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
        </NewsFeedCard>


        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage src={require("images/logo/amarillo-logo.png")} />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>
                Amarillo Named Top 100 Best Places to Live in U.S.
              </NewsFeedCardName>
              <NewsFeedCardJob>Amarillo EDC</NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>August 25 at 4:25 pm</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              Livability named Amarillo one of the best places to live. We sure are proud of our home! Click image below to read more.
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
          <a 
            href="https://livability.com/best-places/2023-top-100-best-places-to-live-in-the-us/amarillo-tx/?utm_content=253925499&utm_medium=social&utm_source=linkedin&hss_channel=lcp-2278053"
            target="_blank">
              <img
                style={{ width: "100%" }}
                src={require("images/top100post.png")}
              />
          </a>
        </NewsFeedCard>

        {/* <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage src={require("images/logo/amarillo-logo.png")} />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>How much Does It Really Cost?</NewsFeedCardName>
              <NewsFeedCardJob>Cost Of Living In Amarillo</NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>August 21 at 3:10 pm</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
            In Amarillo, Texas, the possibilities are as wide open as the enormous blue sky. Filled with independent thinkers and bootstrapping entrepreneurs, this is a community where everyone is your neighbor. Everyone is rooting for you. Everyone is invested in your success.
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
          <NewsFeedVideoContainer>
            <video controls preload="metadata">
              <source type="video/mp4" src="/videos/video-1.mp4#t=0.1" />
            </video>
          </NewsFeedVideoContainer>
          <div>
            <img
              src={require("images/col-2.PNG")}
              style={{
                maxWidth: "100%",
              }}
            />
          </div>
        </NewsFeedCard> */}

        {/* <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage src={require("images/logo/amarillo-logo.png")} />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Take Root In Amarillo</NewsFeedCardName>
              <NewsFeedCardJob>System</NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>June 8 at 7:14 PM</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              You shouldn't have to be at a job that doesn't challenge you to
              move forward.
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>

          <a
            style={{
              textDecoration: "none",
              color: "#000",
            }}
            href="https://takerootinamarillo.com/blog"
            target="_blank"
          >
            <NewsFeedBlogContainer>
              <NewsFeedBlogImg src="https://www.amarilloedc.com/hubfs/amarillo-holiday-blog-main.png" />
              <NewsFeedBlogMetaContainer>
                <NewsFeedBlogHeadline>
                  Take Root In Amarillo
                </NewsFeedBlogHeadline>
                <NewsFeedBlogDescription>
                  Explore neighborhoods, things to do, education, cost of
                  living, the entrepenurial atmosphere, and ways to get
                  connectedto the Amarillo community. Subscribe to the Take Root
                  In Amarillo blog for all this and more!
                </NewsFeedBlogDescription>
              </NewsFeedBlogMetaContainer>
            </NewsFeedBlogContainer>
          </a>
        </NewsFeedCard> */}

        {/* <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage style={{
              width: 40,
              height: 40
            }} src={require("images/logo/amarillo-logo.png")} />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Take Root In Amarillo</NewsFeedCardName>
              <NewsFeedCardJob>
                System
              </NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>August 24 at 2:14 pm</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
            Explore neighborhoods, things to do, education, cost of living, the entrepreneurial atmosphere, and ways to get connected to the Amarillo community. Subscribe to the Take Root In Amarillo blog for all this and more!            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
          <NewsFeedVideoContainer>
            <video controls preload="metadata">
              <source type="video/mp4" src="/videos/tmcf-resource.mp4" />
            </video>
          </NewsFeedVideoContainer>
        </NewsFeedCard> */}

        {this.state.feed.map((feedItem) => {
          return feedItem.attachment === "" ? (
            <NewsFeedCard>
              <NewsFeedCardHeadingContainer>
                <Avatar
                  style={{
                    marginRight: 10,
                  }}
                  width={50}
                  height={50}
                  character={feedItem.user.avatar.slug}
                />
                <NewsFeedCardDetailsContainer>
                  <NewsFeedCardName>
                    {feedItem.user.firstName + " " + feedItem.user.lastName}
                  </NewsFeedCardName>
                  <NewsFeedCardJob>
                    {feedItem.user.hasOwnProperty("recruiterProfile")
                      ? feedItem.user.recruiterProfile.jobTitle +
                        " at " +
                        feedItem.user.recruiterProfile.companyName
                      : "Professional Recruiter"}
                  </NewsFeedCardJob>
                </NewsFeedCardDetailsContainer>
                <NewsFeedCardTime>
                  {moment(feedItem.createdAt).fromNow()}
                </NewsFeedCardTime>{" "}
              </NewsFeedCardHeadingContainer>
              <NewsFeedCardDescriptionContainer>
                <NewsFeedCardDescription>
                  {feedItem.message}
                </NewsFeedCardDescription>
              </NewsFeedCardDescriptionContainer>
            </NewsFeedCard>
          ) : (
            <NewsFeedCard>
              <NewsFeedCardHeadingContainer>
                <Avatar
                  style={{
                    marginRight: 10,
                  }}
                  width={50}
                  height={50}
                  character={feedItem.user.avatar.slug}
                />
                <NewsFeedCardDetailsContainer>
                  <NewsFeedCardName>
                    {" "}
                    {feedItem.user.firstName + " " + feedItem.user.lastName}
                  </NewsFeedCardName>
                  <NewsFeedCardJob>
                    {feedItem.user.hasOwnProperty("recruiterProfile")
                      ? feedItem.user.recruiterProfile.jobTitle +
                        " at " +
                        feedItem.user.recruiterProfile.companyName
                      : "Professional Recruiter"}
                  </NewsFeedCardJob>
                </NewsFeedCardDetailsContainer>
                <NewsFeedCardTime>
                  {moment(feedItem.createdAt).fromNow()}
                </NewsFeedCardTime>
              </NewsFeedCardHeadingContainer>
              <NewsFeedCardDescriptionContainer>
                <NewsFeedCardDescription>
                  {feedItem.message}
                </NewsFeedCardDescription>
              </NewsFeedCardDescriptionContainer>
              <NewsFeedVideoContainer>
                <img
                  style={{
                    width: "100%",
                  }}
                  src={feedItem.attachment}
                />
              </NewsFeedVideoContainer>
            </NewsFeedCard>
          );
        })}

        {/* 
        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage
              src={require("images/icons/Avatar/Penguin.png")}
            />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Chris Hodges</NewsFeedCardName>
              <NewsFeedCardJob>CEO at Take Root In Amarillo | Job Up</NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>June 19 at 4:18 PM</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              Hey, this is Chris from Take Root In Amarillo | Job Up. I'm excited to have you join our
              platform to make the journey of finding the perfect job for you
              effortless, and simple. Take Root In Amarillo | Job Up fights for you both in terms of
              privacy, and the best job for you. We hope you enjoy using Take Root In Amarillo | Job Up.
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
        </NewsFeedCard> */}

        {/* <BrowserView>
          <NewsFeedCard>
            <BadgesContainer>
              <GoGigLogo src={require("images/logo/GoGig.jpg")} />
              <DownloadOnGoGig
                style={{
                  marginLeft: 30,
                  flexGrow: 1
                }}
              >
                Download Take Root In Amarillo | Job Up
              </DownloadOnGoGig>
              <div>
                <GetOnApple src="https://selinko.com/site/wp-content/uploads/2017/10/itunes-app-store-logo-300x104.png" />
                <GetOnGoogle src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png" />
              </div>
            </BadgesContainer>
          </NewsFeedCard>
        </BrowserView> */}

        {/* <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage src={require("images/icons/Avatar/Tiger.png")} />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Alex Allen</NewsFeedCardName>
              <NewsFeedCardJob>
                Customer Success Manager at Take Root In Amarillo | Job Up
              </NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>March 27 at 2:26 pm</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              You shouldn't have to be at a job that doesn't challenge you to
              move forward.
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
          <NewsFeedVideoContainer>
            <video controls preload="metadata">
              <source type="video/mp4" src="/videos/gogig.mp4#t=50" />
            </video>
          </NewsFeedVideoContainer>
        </NewsFeedCard> */}

        {/* <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage
              src={require("images/icons/Avatar/Tiger.png")}
            />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Alex Allen</NewsFeedCardName>
              <NewsFeedCardJob>
                Customer Success Manager at Take Root In Amarillo | Job Up
              </NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>Onboarding Pt. 1</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              You shouldn't have to be at a job that doesn't challenge you to
              move forward.
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
          <NewsFeedVideoContainer>
            <video controls preload="metadata">
              <source type="video/mp4" src="/videos/gogig.mp4#t=50" />
            </video>
          </NewsFeedVideoContainer>
        </NewsFeedCard>

        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage
              src={require("images/icons/Avatar/Tiger.png")}
            />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Alex Allen</NewsFeedCardName>
              <NewsFeedCardJob>
                Customer Success Manager at Take Root In Amarillo | Job Up
              </NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>Onboarding Pt. 2</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              Don't worry about having to do all the hard work required with
              searching for your next position to move up. Use Take Root In Amarillo | Job Up effectively
              to make opportunities come to you.
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
          <NewsFeedVideoContainer>
            <video controls preload="metadata">
              <source type="video/mp4" src="/videos/gogig2.mp4#t=40" />
            </video>
          </NewsFeedVideoContainer>
        </NewsFeedCard>

        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage
              src={require("images/icons/Avatar/Tiger.png")}
            />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Alex Allen</NewsFeedCardName>
              <NewsFeedCardJob>
                Customer Success Manager at Take Root In Amarillo | Job Up
              </NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>Onboarding Pt. 3</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              Because of the anonymous nature of Take Root In Amarillo | Job Up, don't worry about your
              employers finding you. Your identity is completely anonymous.
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
          <NewsFeedVideoContainer>
            <video controls preload="metadata">
              <source type="video/mp4" src="/videos/gogig3.mp4#t=30" />
            </video>
          </NewsFeedVideoContainer>
        </NewsFeedCard>

        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage
              src={require("images/icons/Avatar/Penguin.png")}
            />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Alex Allen</NewsFeedCardName>
              <NewsFeedCardJob>
                Customer Success Manager at Take Root In Amarillo | Job Up
              </NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>Onboarding Pt. 5</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              Don't have any path to promotion? That's fine, Take Root In Amarillo | Job Up will help you
              get to the next level by bringing opportunities directly to you.
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
          <NewsFeedVideoContainer>
            <video controls preload="metadata">
              <source type="video/mp4" src="/videos/gogig5.mp4#t=10.0" />
            </video>
          </NewsFeedVideoContainer>
        </NewsFeedCard>

        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage
              src={require("images/icons/Avatar/Tiger.png")}
            />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Alex Allen</NewsFeedCardName>
              <NewsFeedCardJob>
                Customer Success Manager at Take Root In Amarillo | Job Up
              </NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>Onboarding Pt. 4</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              Our customer support is available all the time. If you have any
              issues with your profile, send our team an email at
              support@gogig.com
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
          <NewsFeedVideoContainer>
            <video controls preload="metadata">
              <source type="video/mp4" src="/videos/gogig4.mp4#t=20" />
            </video>
          </NewsFeedVideoContainer>
        </NewsFeedCard> */}
      </div>
    );
  }
}

export default UserFeed;

const CompleteProfileCard = styled.div`
  background: white;
  padding: 20px;
  margin-top: 20px;
`;

const BadgesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const GoGigLogo = styled.img`
  width: 60px;
`;
const DownloadOnGoGig = styled.p`
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 15px;
  font-weight: bold;
`;
const GetOnApple = styled.img`
  height: 40px;
`;
const GetOnGoogle = styled.img`
  height: 60px;
`;

const NewsFeedBlogContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px;
  border: 1px solid #f5f5f5;
  align-items: center;
`;
const NewsFeedBlogImg = styled.img`
  height: 100px;
  width: 100px;
  margin-right: 10px;
  object-fit: cover;
`;
const NewsFeedBlogMetaContainer = styled.div``;
const NewsFeedBlogHeadline = styled.p`
  font-weight: bold;
  font-size: 14px;
  margin: 0;
`;
const NewsFeedBlogDescription = styled.p`
  font-size: 12px;
`;

const CompleteProfileCardTitleContainer = styled.div`
  border-bottom: 1px solid #f5f5f5;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
`;
const CompleteProfileCardTitle = styled.p`
  font-weight: bold;
  font-size: 14px;
  margin: 0;
  margin-bottom: 10px;
  padding: 0;
  margin-left: 10px;
`;
const CompleteProfileCardDescription = styled.p`
  margin: 0;
  font-size: 14px;
  margin-bottom: 10px;
`;
const CompleteProfileCardButton = styled.button``;

const NewsFeedCard = styled.div`
  background: white;
  margin-top: 20px;
  padding: 20px;
`;
const NewsFeedCardHeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 10px;
`;

const NewsFeedCardImage = styled.img`
  width: 80px;
  height: 50px;
  border-radius: 50px;
  margin-right: 10px;
`;

const NewsFeedCardDetailsContainer = styled.div`
  flex: 3;
`;
const NewsFeedCardName = styled.p`
  margin: 0;
  font-weight: bold;
  font-size: 14px;
  margin: 0;
`;
const NewsFeedCardJob = styled.p`
  font-size: 12px;
  color: #999;
  margin: 0;
`;
const NewsFeedCardTime = styled.p`
  font-size: 12px;
  color: #999;
  margin: 0;
`;
const NewsFeedCardDescriptionContainer = styled.div``;
const NewsFeedCardDescription = styled.p`
  color: #999;
  margin: 0;
  font-size: 14px;
  padding: 10px;
`;

const NewsFeedVideoContainer = styled.div`
  video {
    width: 100%;
  }
`;

const StyledSection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #f5f5f5;
  text-align: center;
  height: 100px;
  border-radius: 8px;
`;

const ProfilePhoto = styled.img`
  display: block;
  overflow: hidden;
  width: 100%;
  max-height: 500px;
`;
