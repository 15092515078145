import React, { Component } from "react";
import API from "API";
import { Modal, Form, Button, Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Slider from "rc-slider";
import { GeoSelectState, GeoSelectCityTX } from "GeoSelect";
import "rc-slider/assets/index.css";
import { GeoSelectCityAmarillo } from "../GeoSelect";
const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

const officeTypes = ["Remote Work", "Office Work", "Office-first hybrid", "Remote-first hybrid"]

class EditSearchQueryModal extends Component {
  state = {
    loading: false,
    industries: [],
    jobPositions: [],
    jobPositionSpecialties: [],
    companyTypes: [],
    jobLevels: [],
    degrees: [],
    employmentTypes: [],
    employmentType: {},
    jobSearchStages: [],
    searchTitle: "",
    hiringCompany: "",
    jobPosition: {},
    jobPositionSpecialty: {},
    industry: {},
    degree: {},
    salaryMin: 0,
    salaryMax: 0,
    jobLevel: {},
    jobSearchStage: {},
    workExperienceMin: 0,
    workExperienceMax: 0,
    companyType: {},
    editData: {},
    skills: "",
    modal: {
      currentStep: 0,
    },
    states: [],
    cities: [],
    searchDistanceMin: 0,
    searchDistanceMax: 0,
    officeType: "",
  };
  componentDidMount() {
    API.get(
      `/UserProfiles/${this.props.userToken.userId}?filter={
      "include": ["recruiterProfile", "jobPosition", "jobPositionSpecialty",  "jobSearchStage", "companyType", "jobLevel", "degree", "employmentType"]
  }`
    ).then((response) => {
      this.setState(response.data);
    });

    API.get(`/Industries?filter={"order": "name ASC" }`).then((response) => {
      this.setState({
        industries: response.data,
      });
    });

    API.get(`/JobPositions?filter={"order": "name ASC" }`).then((response) => {
      this.setState({
        jobPositions: response.data,
      });
    });

    API.get(`/JobPositionSpecialties?filter={"order": "name ASC" }`).then(
      (response) => {
        this.setState({
          jobPositionSpecialties: response.data,
        });
      }
    );

    API.get(`/CompanyTypes?filter={"order": "priority ASC"}`).then(
      (response) => {
        this.setState({
          companyTypes: response.data,
        });
      }
    );

    API.get(`/JobLevels?filter={"order": "priority ASC"}`).then((response) => {
      this.setState({
        jobLevels: response.data,
      });
    });

    API.get(`/Degrees?filter={"order": "priority DESC"}`).then((response) => {
      this.setState({
        degrees: response.data,
      });
    });

    API.get(`/EmploymentTypes`).then((response) => {
      this.setState({
        employmentTypes: response.data,
      });
    });

    API.get(`/JobSearchStages`).then((response) => {
      this.setState({
        jobSearchStages: response.data,
      });
    });
  }

  handleChange = (e) => {
    let { name, value } = e.target;
    try {
      value = JSON.parse(value);
      this.setState({
        [name]: value,
      });
    } catch (e) {
      this.setState({
        [name]: value,
      });
    }
  };

  handleSubmit = ({ skipSkills }) => {
    this.setState({
      loading: true,
    });
    if (skipSkills === true || this.state.skills.trim() === "") {
      API.put(`/RecruiterSearchQueries/${this.props.editSearchQueryId}`, {
        searchTitle: this.state.searchTitle,
        hiringCompany: this.state.hiringCompany,
        officeType: this.state.officeType,
        jobPositionId: this.state.jobPosition.id,
        jobPositionSpecialtyId: this.state.jobPositionSpecialty.id,
        industryId: this.state.industry.id,
        degreeId: this.state.degree.id,
        salaryMin: this.state.salaryMin,
        salaryMax: this.state.salaryMax,
        jobLevelId: this.state.jobLevel.id,
        jobSearchStageId: this.state.jobSearchStage.id,
        workExperienceMin: this.state.workExperienceMin,
        workExperienceMax: this.state.workExperienceMax,
        companyTypeId: this.state.companyType.id,
        userProfileId: this.props.userToken.userId,
        cities: this.state.cities,
        states: this.state.states,
        searchDistanceMax: this.state.searchDistanceMax,
        searchDistanceMin: this.state.searchDistanceMin,
        employmentTypeId: this.state.employmentType.id,
      }).then((response) => {
        this.props.handleModalShow("");
        this.setState({
          loading: false,
          modal: {
            currentStep: 0,
          },
        });
      });
    } else {
      API.put(`/RecruiterSearchQueries/${this.props.editSearchQueryId}`, {
        searchTitle: this.state.searchTitle,
        hiringCompany: this.state.hiringCompany,
        officeType: this.state.officeType,
        jobPositionId: this.state.jobPosition.id,
        jobPositionSpecialtyId: this.state.jobPositionSpecialty.id,
        industryId: this.state.industry.id,
        degreeId: this.state.degree.id,
        salaryMin: this.state.salaryMin,
        salaryMax: this.state.salaryMax,
        jobLevelId: this.state.jobLevel.id,
        jobSearchStageId: this.state.jobSearchStage.id,
        workExperienceMin: this.state.workExperienceMin,
        workExperienceMax: this.state.workExperienceMax,
        companyTypeId: this.state.companyType.id,
        userProfileId: this.props.userToken.userId,
        skills: this.state.skills
          .trim()
          .split(",")
          .map((skill) => skill.trim()),
        cities: this.state.cities,
        states: this.state.states,
        searchDistanceMax: this.state.searchDistanceMax,
        searchDistanceMin: this.state.searchDistanceMin,
        employmentTypeId: this.state.employmentType.id,
      }).then((response) => {
        this.props.handleModalShow("");
        this.setState({
          loading: false,
          modal: {
            currentStep: 0,
          },
        });
      });
    }
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.editSearchQueryId !== this.props.editSearchQueryId) {
      API.get(
        `/RecruiterSearchQueries/${this.props.editSearchQueryId}?filter={
          "include": ["recruiterProfile", "jobPosition", "jobPositionSpecialty", "industry", "jobSearchStage", "companyType", "jobLevel", "degree", "employmentType"]
        }`
      ).then((response) => {
        this.setState(response.data);
        if ("skills" in response.data) {
          this.setState({
            skills: response.data.skills.join(", "),
          });
        }
      });
    }
  };

  onSliderChange = (name, value) => {
    if (name === "salary") {
      this.setState({
        salaryMin: value[0],
        salaryMax: value[1],
      });
    }

    if (name === "experience") {
      this.setState({
        workExperienceMin: value[0],
        workExperienceMax: value[1],
      });
    }

    if (name == "searchDistance") {
      this.setState({
        searchDistanceMin: value,
      });
    }
  };

  handleNext = () => {
    this.setState({
      modal: {
        currentStep: this.state.modal.currentStep + 1,
      },
    });
  };

  handleBack = () => {
    this.setState({
      modal: { currentStep: this.state.modal.currentStep - 1 },
    });
  };

  handleSelectChange = (inputVals, opt) => {
    let { name } = opt;
    this.setState({
      [name]: [inputVals],
    });
  };

  render() {
    return (
      <Modal
        show={this.props.modalShow === "edit"}
        size="lg"
        dialogClassName="modal-65w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          {
            {
              0: (
                <ModalContainer>
                  <ModalColumn>
                    <img
                      style={{
                        width: 380,
                        bottom: 0,
                      }}
                      src={require("images/modals/gogig-career-desires.png")}
                    />
                  </ModalColumn>
                  <ModalColumn className="p-5 w-100 bg-white">
                    <h1>Edit Your Take Root In Amarillo | Job Up Search</h1>
                    <p className="text-muted">
                      You can edit any fields in your search.
                    </p>
                    <Form.Row>
                      <Col>
                        <Form.Label>Search Title</Form.Label>
                        <Form.Control
                          type="text"
                          name="searchTitle"
                          onChange={this.handleChange}
                          placeholder="Search Title"
                          value={this.state.searchTitle}
                        />
                      </Col>
                      <Col>
                        <Form.Label>Hiring Company</Form.Label>
                        <Form.Control
                          type="text"
                          name="hiringCompany"
                          onChange={this.handleChange}
                          placeholder="Company you're hiring on behalf of"
                          value={this.state.hiringCompany}
                        />
                      </Col>
                    </Form.Row>
                    <Form.Row className="mt-4">
                      <Col>
                        <Form.Label>
                          What type of position are you hiring for?
                        </Form.Label>
                        <Form.Control
                          as="select"
                          onChange={this.handleChange}
                          name="jobPosition"
                          value={JSON.stringify(this.state.jobPosition)}
                        >
                          <option disabled value={JSON.stringify({})}>
                            Search Position Type
                          </option>
                          {this.state.jobPositions.map((jobPosition) => (
                            <option value={JSON.stringify(jobPosition)}>
                              {jobPosition.name}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                    </Form.Row>
                    <Form.Row className="mt-4">
                      <Col>
                        <Form.Label>
                          What specialty within {this.state.jobPosition.name}{" "}
                          are you hiring for?
                        </Form.Label>
                        <Form.Control
                          as="select"
                          onChange={this.handleChange}
                          name="jobPositionSpecialty"
                          value={JSON.stringify(
                            this.state.jobPositionSpecialty
                          )}
                        >
                          <option disabled value={JSON.stringify({})}>
                            Select A Position Specialty
                          </option>
                          {this.state.jobPositionSpecialties.map(
                            (jobPositionSpecialty) => {
                              if (
                                jobPositionSpecialty.jobPositionId ===
                                this.state.jobPosition.id
                              ) {
                                return (
                                  <option
                                    value={JSON.stringify(jobPositionSpecialty)}
                                  >
                                    {jobPositionSpecialty.name}
                                  </option>
                                );
                              }
                            }
                          )}
                        </Form.Control>
                      </Col>
                    </Form.Row>
                    <Form.Row className="mt-4">
                      <Col>
                        <Form.Label>Hiring Company Industry</Form.Label>
                        <Form.Control
                          as="select"
                          name="industry"
                          value={JSON.stringify(this.state.industry)}
                          onChange={this.handleChange}
                        >
                          <option disabled value={JSON.stringify({})}>
                            Choose a field
                          </option>
                          {this.state.industries.map((industry) => (
                            <option value={JSON.stringify(industry)}>
                              {industry.name}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                      <Col>
                        <Form.Label>Minimum Degree Level Required</Form.Label>
                        <Form.Control
                          as="select"
                          name="degree"
                          value={JSON.stringify(this.state.degree)}
                          onChange={this.handleChange}
                        >
                          <option disabled value={JSON.stringify({})}>
                            Choose a field
                          </option>
                          {this.state.degrees.map((degree) => (
                            <option value={JSON.stringify(degree)}>
                              {degree.name}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                    </Form.Row>

                    <Form.Row className="mt-4">
                      <Col>
                        <Form.Label>Job Location</Form.Label>
                        <GeoSelectCityAmarillo
                          isMulti={false}
                          name="cities"
                          handleChange={this.handleSelectChange}
                          value={this.state.cities}
                          placeholder="Enter City and State"
                        />
                      </Col>
                    </Form.Row>

                    {this.state.cities.length !== 0 && (
                      <Form.Row className="mt-5">
                        <Col>
                          <Form.Row>
                            <Form.Label>Search Distance</Form.Label>
                            <SliderValueContainer>
                              <SliderValue>
                                {this.state.searchDistanceMin === 100
                                  ? "Max"
                                  : this.state.searchDistanceMin + "mi"}
                              </SliderValue>
                            </SliderValueContainer>
                          </Form.Row>
                          <Slider
                            min={0}
                            max={100}
                            onChange={(d) =>
                              this.onSliderChange("searchDistance", d)
                            }
                            marks={{
                              0: "0mi",

                              100: "100mi",
                            }}
                            value={this.state.searchDistanceMin}
                            tipFormatter={(value) => `${value}%`}
                            railStyle={{
                              backgroundColor: "#f5f5f5",
                              height: 10,
                            }}
                            trackStyle={[
                              {
                                background:
                                  "linear-gradient(90deg, rgba(233,151,151,1) 0%, rgba(242,183,144,1) 35%, rgba(255,225,127,1) 100%)",
                                height: 10,
                              },
                              {
                                background:
                                  "linear-gradient(90deg, rgba(233,151,151,1) 0%, rgba(242,183,144,1) 35%, rgba(255,225,127,1) 100%)",
                                height: 10,
                              },
                            ]}
                            handleStyle={[
                              {
                                background:
                                  "linear-gradient(90deg, rgba(233,151,151,1) 0%, rgba(242,183,144,1) 35%, rgba(255,225,127,1) 100%)",
                                borderColor: "#ffe17f",
                                width: 17,
                                height: 17,
                                top: 6,
                              },
                              {
                                background:
                                  "linear-gradient(90deg, rgba(233,151,151,1) 0%, rgba(242,183,144,1) 35%, rgba(255,225,127,1) 100%)",
                                borderColor: "#ffe17f",
                                width: 17,
                                height: 17,
                                top: 6,
                              },
                            ]}
                            dotStyle={{
                              display: "none",
                            }}
                          />
                        </Col>
                      </Form.Row>
                    )}

                    <Form.Row className="mt-5">
                      <Col>
                        <Form.Row>
                          <Form.Label>Salary Range</Form.Label>
                          <SliderValueContainer>
                            <SliderValue>
                              ${this.state.salaryMin} - ${this.state.salaryMax}
                            </SliderValue>{" "}
                          </SliderValueContainer>
                        </Form.Row>
                        <Range
                          min={0}
                          max={500000}
                          onChange={(d) => this.onSliderChange("salary", d)}
                          marks={{
                            0: "$0",
                            500000: "$500,000+",
                          }}
                          step={5000}
                          value={[this.state.salaryMin, this.state.salaryMax]}
                          tipFormatter={(value) => `${value}%`}
                          railStyle={{
                            backgroundColor: "#f5f5f5",
                            height: 10,
                          }}
                          trackStyle={[
                            {
                              background:
                                "linear-gradient(90deg, rgba(233,151,151,1) 0%, rgba(242,183,144,1) 35%, rgba(255,225,127,1) 100%)",
                              height: 10,
                            },
                            {
                              background:
                                "linear-gradient(90deg, rgba(233,151,151,1) 0%, rgba(242,183,144,1) 35%, rgba(255,225,127,1) 100%)",
                              height: 10,
                            },
                          ]}
                          handleStyle={[
                            {
                              background:
                                "linear-gradient(90deg, rgba(233,151,151,1) 0%, rgba(242,183,144,1) 35%, rgba(255,225,127,1) 100%)",
                              borderColor: "#ffe17f",
                              width: 17,
                              height: 17,
                              top: 6,
                            },
                            {
                              background:
                                "linear-gradient(90deg, rgba(233,151,151,1) 0%, rgba(242,183,144,1) 35%, rgba(255,225,127,1) 100%)",
                              borderColor: "#ffe17f",
                              width: 17,
                              height: 17,
                              top: 6,
                            },
                          ]}
                          dotStyle={{
                            display: "none",
                          }}
                        />
                      </Col>
                    </Form.Row>
                    <Form.Row className="mt-5">
                      <Col>
                        <Form.Label>Job Level</Form.Label>
                        <Form.Control
                          as="select"
                          onChange={this.handleChange}
                          name="jobLevel"
                          value={JSON.stringify(this.state.jobLevel)}
                        >
                          <option disabled value={JSON.stringify({})}>
                            Choose a field
                          </option>
                          {this.state.jobLevels.map((jobLevel) => (
                            <option value={JSON.stringify(jobLevel)}>
                              {jobLevel.name}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                      <Col>
                        <Form.Label>Job Type</Form.Label>
                        <Form.Control
                          as="select"
                          name="employmentType"
                          onChange={this.handleChange}
                          value={JSON.stringify(this.state.employmentType)}
                        >
                          <option disabled value={JSON.stringify({})}>
                            Choose a field
                          </option>
                          {this.state.employmentTypes.map((employmentType) => (
                            <option value={JSON.stringify(employmentType)}>
                              {employmentType.name}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                    </Form.Row>
                    <Form.Row className="mt-4">
                      <Col>
                        <Form.Label>Company Type</Form.Label>
                        <Form.Control
                          as="select"
                          name="companyType"
                          value={JSON.stringify(this.state.companyType)}
                          onChange={this.handleChange}
                        >
                          <option disabled value={JSON.stringify({})}>
                            Choose a field
                          </option>
                          {this.state.companyTypes.map((companyType) => (
                            <option value={JSON.stringify(companyType)}>
                              {companyType.name}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                      <Col>
                        <Form.Label>Office Type</Form.Label>
                        <Form.Control
                          as="select"
                          name="officeType"
                          value={JSON.stringify(this.state.officeType)}
                          defaultValue={JSON.stringify({})}
                          onChange={this.handleChange}
                        >
                          <option disabled value={JSON.stringify("")}>
                            Choose a field
                          </option>
                          {officeTypes.map((officeType) => (
                            <option value={JSON.stringify(officeType)}>
                              {officeType}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                    </Form.Row>
                    <Form.Row className="mt-5">
                      <Col>
                        <Form.Row>
                          <Form.Label>Total Years Work Experience</Form.Label>
                          <SliderValueContainer>
                            <SliderValue>
                              {this.state.workExperienceMin} years -{" "}
                              {this.state.workExperienceMax} years
                            </SliderValue>
                          </SliderValueContainer>
                        </Form.Row>
                        <Range
                          value={[
                            this.state.workExperienceMin,
                            this.state.workExperienceMax,
                          ]}
                          min={0}
                          max={25}
                          onChange={(d) => this.onSliderChange("experience", d)}
                          marks={{
                            0: "0",

                            25: "25+ Yrs",
                          }}
                          tipFormatter={(value) => `${value}%`}
                          railStyle={{
                            backgroundColor: "#f5f5f5",
                            height: 10,
                          }}
                          trackStyle={[
                            {
                              background:
                                "linear-gradient(90deg, rgba(233,151,151,1) 0%, rgba(242,183,144,1) 35%, rgba(255,225,127,1) 100%)",
                              height: 10,
                            },
                            {
                              background:
                                "linear-gradient(90deg, rgba(233,151,151,1) 0%, rgba(242,183,144,1) 35%, rgba(255,225,127,1) 100%)",
                              height: 10,
                            },
                          ]}
                          handleStyle={[
                            {
                              background:
                                "linear-gradient(90deg, rgba(233,151,151,1) 0%, rgba(242,183,144,1) 35%, rgba(255,225,127,1) 100%)",
                              borderColor: "#ffe17f",
                              width: 17,
                              height: 17,
                              top: 6,
                            },
                            {
                              background:
                                "linear-gradient(90deg, rgba(233,151,151,1) 0%, rgba(242,183,144,1) 35%, rgba(255,225,127,1) 100%)",
                              borderColor: "#ffe17f",
                              width: 17,
                              height: 17,
                              top: 6,
                            },
                          ]}
                          dotStyle={{
                            display: "none",
                          }}
                        />
                      </Col>
                    </Form.Row>
                    <Form.Row className="mt-5">
                      <Button
                        variant="outline-primary"
                        className="  mr-2"
                        onClick={() => this.props.handleModalShow("")}
                      >
                        Cancel
                      </Button>
                      <Button className="ml-auto" onClick={this.handleNext}>
                        Next
                      </Button>
                    </Form.Row>
                  </ModalColumn>
                </ModalContainer>
              ),
              1: (
                <ModalContainer>
                  <ModalColumn>
                    <img
                      style={{
                        padding: 50,
                        width: 400,
                      }}
                      src={require("images/modals/recruiter-onboarding-1.png")}
                    />
                  </ModalColumn>
                  <ModalColumn className="w-100 p-5 bg-white">
                    <h1>Skills Evaluator</h1>
                    <p>
                      The Skills Evaluator allows you to evaluate the top 5
                      skills that you need to know from candidates in this
                      Search. When you match with one candidate, or multiple
                      candidates, they'll be asked to evaluate how well-versed
                      they are in each skill when they receive your match
                      request.
                    </p>
                    <Form>
                      <Form.Row>
                        <Col>
                          <Form.Label>
                            What is your level of expertise in:
                          </Form.Label>
                          <Form.Control
                            name="skills"
                            value={this.state.skills}
                            onChange={this.handleChange}
                            type="text"
                            placeholder="Add up to 5 skills"
                          />
                        </Col>
                      </Form.Row>
                      <Form.Row
                        className="flex mt-4 "
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Form.Row
                          className="flex mt-4 "
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          {this.state.loading ? (
                            <Button className="">Loading...</Button>
                          ) : (
                            <Button className="" onClick={this.handleSubmit}>
                              Run Search with Skills Evaluation
                            </Button>
                          )}
                          {this.state.loading ? (
                            <p
                              style={{
                                color: "#e99797",
                                fontSize: 12,
                                fontWeight: "bold",
                                textDecoration: "underline",
                                marginTop: 10,
                                cursor: "pointer",
                              }}
                            >
                              Loading...
                            </p>
                          ) : (
                            <p
                              onClick={(e) =>
                                this.handleSubmit({ skipSkills: true })
                              }
                              style={{
                                color: "#e99797",
                                fontSize: 12,
                                fontWeight: "bold",
                                textDecoration: "underline",
                                marginTop: 10,
                                cursor: "pointer",
                              }}
                            >
                              Skip Skills Evaluation and Run Search
                            </p>
                          )}
                        </Form.Row>
                      </Form.Row>
                    </Form>
                  </ModalColumn>
                </ModalContainer>
              ),
            }[this.state.modal.currentStep]
          }
        </Modal.Body>
      </Modal>
    );
  }
}

export default EditSearchQueryModal;

const BackgroundImg = styled.div`
  width: 400px;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://farm4.staticflickr.com/3902/14985871946_24f47d4b53_h.jpg");
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0;
  margin: -16px;
`;
const ModalColumn = styled.div`
  background: #edeff0;
`;

const SliderValueContainer = styled.p`
  margin: 0;
  padding: 0;
  margin-left: auto;
  font-weight: 500;
  color: #020000;
`;
const SliderValue = styled.p`
  margin: 0;
  padding: 0;
`;
