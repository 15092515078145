import React from "react";
import styled from "styled-components";
import Search from "../images/icons/Search.svg";
import { Paragraph } from "./Typography.stories";
import { PrimaryButton } from "./Buttons.stories";
import { Avatar } from "./Avatars.stories";

export default {
  title: "Cards",
};

const CardContainer = styled.div`
  border: 1px solid #efefef;
  background: white;
  padding: 20px;
  max-width: 600px;
`;
const CardHeaderGoGigContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #efefef;
  padding: 5px;
`;
const CardHeaderGoGigIcon = styled.img`
  margin-left: 10px;
  margin-right: 20px;
`;
const CardBodyContainer = styled.div``;
const CardHeaderFooterContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

const CardHeaderUserContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #efefef;
  padding: 5px;
`;
const CardHeaderUserAvatarWrapper = styled.div`
  margin-right: 10px;
`;
const CardHeaderUserMetaWrapper = styled.div``;
const CardHeaderUserPostedAgoWrapper = styled.div`
  margin-left: auto;
`;

export const GoGigFeedCard = () => (
  <CardContainer>
    <CardHeaderGoGigContainer>
      <CardHeaderGoGigIcon src={Search} />
      <Paragraph>
        <strong>
          Your profile is not discoverable to recruiters within Take Root In
          Amarillo | Job Up
        </strong>
      </Paragraph>
    </CardHeaderGoGigContainer>
    <CardBodyContainer>
      <Paragraph size="14px">
        Your profile is 70% complete. In order for you to become discoverable to
        Hiring Companies in Take Root In Amarillo | Job Up and view your
        culutural fit report, you must complete your profile.
      </Paragraph>
    </CardBodyContainer>
    <CardHeaderFooterContainer>
      <PrimaryButton>Complete Profile</PrimaryButton>
    </CardHeaderFooterContainer>
  </CardContainer>
);
export const UserFeedCard = () => (
  <CardContainer>
    <CardHeaderUserContainer>
      <CardHeaderUserAvatarWrapper>
        <Avatar height={45} width={45} />
      </CardHeaderUserAvatarWrapper>
      <CardHeaderUserMetaWrapper>
        <Paragraph removeMargins size="14px">
          <strong>Chris Hodges</strong>
        </Paragraph>
        <Paragraph removeMargins size="12px" muted>
          CEO at GoGig
        </Paragraph>
      </CardHeaderUserMetaWrapper>
      <CardHeaderUserPostedAgoWrapper>
        <Paragraph size="12px" muted>
          3 hours
        </Paragraph>
      </CardHeaderUserPostedAgoWrapper>
    </CardHeaderUserContainer>
    <CardBodyContainer>
      <Paragraph muted size="14px">
        Hey there candidates, my name is Chris, we are hiring a new engineer for
        our Delray Beach office. Please send us a match request to have a chat!
      </Paragraph>
    </CardBodyContainer>
  </CardContainer>
);
