import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Navbar,
  Nav,
  Modal,
  Button,
  Form,
  Badge,
  Pill,
} from "react-bootstrap";
import { ProfileCard } from "Stories/ProfileCard.stories";
import API from "API";
import { Link } from "react-router-dom";
import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import styled from "styled-components";
import { NavBarWithItems } from "Stories/Navigation.stories.js";
import { ReactComponent as SettingsIcon } from "images/icons/Settings.svg";

class GeneralSettingsScreen extends Component {
  state = {
    email: "",
    firstName: "",
    lastName: "",
    isValidated: false,
  };

  componentDidMount() {
    API.get(`/UserProfiles/${this.props.userToken.userId}`).then((response) => {
      this.setState(response.data);
    });
  }

  handleChange = (e) => {
    let { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = (e) => {
    const form = e.target;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      this.setState({isValidated: true})
    } else {
      API.patch(`/UserProfiles/${this.props.userToken.userId}`, {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
      }).then((response) => {
        this.setState({
          firstName: response.data.firstName,
          lastName: response.data.lastName,
        })
      })
      .catch((error) => {
        this.setState({
          error: error,
        });
      });
    }
  };

  handleSendVerificationEmail = () => {
    API.post(`/UserEmailVerificationCodes`, {
      userProfileId: this.state.id,
      email: this.state.email,
    });
  };

  render() {
    return (
      <Container>
        <NavBarWithItems {...this.props} />
        <Row>
          <Col md={3}>
            <ProfileCard {...this.props} />
          </Col>
          <Col>
            <SettingsContainer>
              <SettingsHeading>
                <div>
                  <SettingsIcon />
                </div>
                <SettingsTitle>General</SettingsTitle>
              </SettingsHeading>
              <Form noValidate validated={this.state.isValidated} onSubmit={this.handleSubmit}>
                <Form.Row>
                  <Col>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Email"
                      value={this.state.email}
                      disabled={true}
                    />
                    <Form.Text className="text-muted">
                      Please email support@gogig.com to update your email.
                    </Form.Text>
                  </Col>
                </Form.Row>
                <Form.Row className="mt-3">
                  <Col>
                    {this.state.emailVerified ? (
                      <div>
                        <Form.Label className="mr-1">
                          Email Verification Status:{" "}
                        </Form.Label>
                        <Form.Label className="text-success">
                          Verification Complete
                        </Form.Label>
                      </div>
                    ) : (
                      <div>
                        <Form.Label className="mr-1">
                          Email Verification Status:{" "}
                        </Form.Label>
                        <Form.Label style={{ color: "gold" }}>
                          Verification Pending
                        </Form.Label>
                        <Button
                          size="sm"
                          className="float-right"
                          onClick={this.handleSendVerificationEmail}
                        >
                          Send Email Verification Link
                        </Button>
                      </div>
                    )}
                  </Col>
                </Form.Row>
                <Form.Row className="mt-3">
                  <Col>
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      name="firstName"
                      value={this.state.firstName}
                      onChange={this.handleChange}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid first name.
                    </Form.Control.Feedback>    
                  </Col>
                  <Col>
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Last Name"
                      name="lastName"
                      value={this.state.lastName}
                      onChange={this.handleChange}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid last name.
                    </Form.Control.Feedback>    
                  </Col>
                </Form.Row>
                <Form.Row className="mt-4">
                  <Col>
                    <Button
                      // disabled
                      type="submit"
                      variant="outline-primary float-right"
                    >
                      Update
                    </Button>
                  </Col>
                </Form.Row>
              </Form>
            </SettingsContainer>
          </Col>
        </Row>
      </Container>
    );
  }
}

const SettingsContainer = styled.div`
  background: white;
  border: 3px solid #f5f5f5;
  flex: 1;
  margin-top: 20px;
  padding: 40px;
`;
const SettingsHeading = styled.div`
  border-bottom: 1px solid #f5f5f5;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
`;
const SettingsTitle = styled.p`
  font-size: 16px;
  font-weight: bold;
  margin-left: 15px;
  .;
`;

export default GeneralSettingsScreen;
