import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import LockIcon from "@material-ui/icons/Lock";
import MenuBookIcon from "@material-ui/icons/MenuBook";

import classes from "./index.module.css";

function TemplateFooter(props) {
  return (
    <footer className={classes.footerContainer}>
      <Grid container className={classes.footerGrid}>
        <Grid item xs={0} lg={2} />
        <Grid className={classes.footerSectionContainer} item xs={12} lg={3}>
          {/* <Typography
            className={classes.footerText}
          >
            Connect with us
          </Typography>
          <a
            href="https://www.instagram.com/hello.amarillo/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className={classes.footerIcon}
              src="/icons/instagram-darkblue-icon.svg"
              alt="Instagram Small Logo"
            />
          </a>
          <a
            href="https://www.facebook.com/helloamarillo"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className={classes.footerIcon}
              src="/icons/facebook-darkblue-icon.svg"
              alt="Facebook Small Logo"
            />
          </a>
          <a
            href="https://twitter.com/hello_amarillo"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className={classes.footerIcon}
              src="/icons/twitter-darkblue-icon.svg"
              alt="Twitter Small Logo"
            />
          </a> */}
          {/* <a
            href="https://www.linkedin.com/company/tmcf/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className={classes.footerIcon}
              src="/icons/linkedin-darkblue-icon.svg"
              alt="LinkedIn Small Logo"
            />
          </a> */}
          {/* <a
            href='https://www.amarilloedc.com/take-root'
            target="_blank"
            rel="noreferrer"
          >
            <img
              className={classes.footerIcon}
              src="/icons/small-house-darkblue-icon.svg"
              alt="Home Logo"
            />
          </a> */}

          <Link to="/support">
            <div className={classes.linkContainer}>
              <Typography
                className={classes.linkNeutral}
                style={{
                  color: "#3E4D5A",
                  fontSize: "0.875rem",
                  fontFamily: "Open Sans",
                  marginRight: "10px",
                }}
              >
                Resources
              </Typography>
              <MenuBookIcon
                style={{
                  fill: "#3E4D5A",
                }}
                className={classes.footerIcon}
              />
            </div>
          </Link>
        </Grid>
        <Grid
          className={classes.footerSectionContainer}
          item
          xs={12}
          lg={2}
        ></Grid>
        <Grid className={classes.footerSectionContainer} item xs={12} lg={3}>
          <img
            className={classes.footerGogigLogo}
            src="/icons/poweredby-gogig-logo-darkblue.svg"
            alt=""
          />
        </Grid>
        <Grid item xs={0} lg={2} />
      </Grid>
    </footer>
  );
}

TemplateFooter.propTypes = {};

export default TemplateFooter;
