import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import data from './constants/mycarepathContent';
import RootLandingTemplate from './components/RootLandingTemplate';
import TemplateFooter from './components/TemplateFooter';

function LandingRoot(props) {
  const { amarillo } = data;
  return (
    <>
      <RootLandingTemplate
        {...amarillo}
      />
      <TemplateFooter
        {...amarillo}
      />
    </>
  )
}

LandingRoot.propTypes = {}

export default LandingRoot
