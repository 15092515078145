import React, { useState } from 'react';
import PropTypes from 'prop-types';

import data from './constants/mycarepathContent';
import InfoLandingTemplate from './components/InfoLandingTemplate';
import TemplateFooter from './components/TemplateFooter';

function LandingInfo(props){
  const { amarillo } = data;
  return (
    <>
      <InfoLandingTemplate
        {...amarillo}
      />
      <TemplateFooter
        {...amarillo}
      />
    </>
  )
}

LandingInfo.propTypes = {}

export default LandingInfo;
