import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Navbar,
  Nav,
  Modal,
  Button,
  Form,
  Badge,
  Pill,
  Tabs,
  Tab,
} from "react-bootstrap";
import { ProfileCard } from "Stories/ProfileCard.stories";
import API from "API";
import { Link } from "react-router-dom";
import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import styled from "styled-components";
import { NavBarWithItems } from "Stories/Navigation.stories.js";
import { ReactComponent as FeedbackIcon } from "images/icons/Feedback.svg";
import BadIcon from "images/icons/feedback/Bad/On.svg";
import GreatIcon from "images/icons/feedback/Great/On.svg";
import OkIcon from "images/icons/feedback/Ok/On.svg";
import Dropzone from "react-dropzone";
import axios from "axios";
import { Avatar } from "Stories/Avatars.stories.js";

class ProfilePhotoScreen extends Component {
  state = {
    avatar: {
      slug: "",
    },
    avatars: [],
  };

  componentDidMount = () => {
    API.get(`/UserProfiles/${this.props.userToken.userId}`).then((response) => {
      console.log(response);
      this.setState(response.data);
    });
    API.get(`/Avatars`).then((response) => {
      this.setState({
        avatars: response.data,
      });
    });
  };

  uploadFile = (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      let formData = new FormData();
      formData.append("newFormData", file);
      axios
        .post(
          "https://my.gogig.com:4000/api/Attachments/gogig-v3/upload",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          API.patch(`/UserProfiles/${this.props.userToken.userId}`, {
            profilePhoto:
              "https://my.gogig.com:4000/api/Attachments/gogig-v3/download/" +
              response.data.result.files.newFormData[0].name,
          });
          this.setState({
            profilePhoto:
              "https://my.gogig.com:4000/api/Attachments/gogig-v3/download/" +
              response.data.result.files.newFormData[0].name,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    });
  };

  uploadCoverPhoto = (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      let formData = new FormData();
      formData.append("newFormData", file);
      axios
        .post(
          "https://my.gogig.com:4000/api/Attachments/gogig-v3/upload",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          API.patch(`/UserProfiles/${this.props.userToken.userId}`, {
            coverPhoto:
              "https://my.gogig.com:4000/api/Attachments/gogig-v3/download/" +
              response.data.result.files.newFormData[0].name,
          });
          this.setState({
            coverPhoto:
              "https://my.gogig.com:4000/api/Attachments/gogig-v3/download/" +
              response.data.result.files.newFormData[0].name,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    });
  };

  uploadDefaultPhoto = (whichOne) => {
    var coverPhoto;
    if (whichOne === 1) {
      coverPhoto = "https://gogig-v3.s3.amazonaws.com/amarillo-cover-1.png";
    } else if (whichOne === 2) {
      coverPhoto = "https://gogig-v3.s3.amazonaws.com/amarillo-cover-2.png";
    }

    API.patch(`/UserProfiles/${this.props.userToken.userId}`, {
      coverPhoto: coverPhoto,
    });
    this.setState({
      coverPhoto: coverPhoto,
    });
  };

  handleSelectAvatar = (avatar) => {
    API.patch(`/UserProfiles/${this.props.userToken.userId}`, {
      avatarId: avatar.id,
    });
    this.setState({
      avatar: avatar,
    });
  };

  handleRemoveAvatar = () => {
    API.patch(`/UserProfiles/${this.props.userToken.userId}`, {
      profilePhoto: "",
    });
    this.setState({
      profilePhoto: "",
    });
  };

  render() {
    return (
      <Container>
        <NavBarWithItems {...this.props} />
        <Row>
          <Col md={3}>
            <ProfileCard {...this.props} />
          </Col>
          <Col>
            <SettingsContainer>
              <Tabs defaultActiveKey="anon" transition={false}>
                <Tab eventKey="anon" title="Anonymous Avatar Selection">
                  <div className="mt-4">
                    <p>
                      <strong>The Defaults</strong>
                    </p>
                    <AvatarsContainer>
                      <Avatar
                        width={60}
                        height={60}
                        onClick={() =>
                          this.handleSelectAvatar({
                            id: "5ee90af46cc1a4769b483122",
                            name: "Boy",
                            slug: "boy",
                          })
                        }
                        character={"boy"}
                        selection={true}
                        selected={this.state.avatar.slug === "boy"}
                      />
                      <Avatar
                        width={60}
                        height={60}
                        onClick={() =>
                          this.handleSelectAvatar({
                            id: "5ee90b076cc1a4769b483123",
                            name: "Girl",
                            slug: "girl",
                          })
                        }
                        character={"girl"}
                        selection={true}
                        selected={this.state.avatar.slug === "girl"}
                      />
                      <Avatar
                        width={60}
                        height={60}
                        onClick={() =>
                          this.handleSelectAvatar({
                            id: "5ee90b236cc1a4769b483124",
                            name: "GenderNeutral",
                            slug: "neutral",
                          })
                        }
                        character={"neutral"}
                        selection={true}
                        selected={this.state.avatar.slug === "neutral"}
                      />
                    </AvatarsContainer>
                    <p>
                      <strong>The Classics</strong>
                    </p>
                    <AvatarsContainer>
                      {this.state.avatars
                        .filter((avatar) => avatar.slug.includes("man"))
                        .map((avatar) => (
                          <Avatar
                            width={60}
                            height={60}
                            onClick={() => this.handleSelectAvatar(avatar)}
                            character={avatar.slug}
                            selection={true}
                            selected={this.state.avatar.slug === avatar.slug}
                          />
                        ))}
                    </AvatarsContainer>
                    <p>
                      <strong>The Animals</strong>
                      <AvatarsContainer>
                        {this.state.avatars
                          .filter((avatar) => !avatar.slug.includes("man"))
                          .filter((avatar) => !avatar.slug.includes("boy"))
                          .filter((avatar) => !avatar.slug.includes("girl"))
                          .filter((avatar) => !avatar.slug.includes("neutral"))
                          .map((avatar) => (
                            <Avatar
                              width={60}
                              height={60}
                              onClick={() => this.handleSelectAvatar(avatar)}
                              character={avatar.slug}
                              selection={true}
                              selected={this.state.avatar.slug === avatar.slug}
                            />
                          ))}
                      </AvatarsContainer>
                    </p>
                  </div>
                  <div className="pb-5 mr-5 mt-3">
                    <Button
                      onClick={this.handleRemoveAvatar}
                      variant="outline-primary"
                      className="float-left"
                    >
                      Remove
                    </Button>

                    <Link to="/getstarted">
                      <Button className="float-right">Save</Button>
                    </Link>
                  </div>
                </Tab>
                <Tab eventKey="cover" title="Cover Photo">
                  <div className="mt-4">
                    <p className="m-0 p-0">
                      <strong>Upload Cover Photo</strong>
                    </p>
                    <small className="text-muted">
                      Use your cover photo as a way to express yourself to
                      Hiring Companies.
                    </small>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <CoverPhoto
                        style={{ marginRight: 10, cursor: "pointer" }}
                        onClick={() => this.uploadDefaultPhoto(1)}
                        src="https://gogig-v3.s3.amazonaws.com/amarillo-cover-1.png"
                      />
                      <CoverPhoto
                        style={{ marginRight: 10, cursor: "pointer" }}
                        onClick={() => this.uploadDefaultPhoto(2)}
                        src="https://gogig-v3.s3.amazonaws.com/amarillo-cover-2.png"
                      />
                    </div>

                    <p style={{ margin: 0, padding: 0 }}>
                      Your Current Selection:{" "}
                    </p>
                    <CoverPhoto
                      style={{ marginTop: 0 }}
                      src={this.state.coverPhoto}
                    />
                    {/* <Dropzone onDrop={this.uploadCoverPhoto}>
                      {({ getRootProps, getInputProps }) => (
                        <StyledSection>
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <p className="text-muted">Drag a photo here</p>
                            <p className="text-muted">Size: 250px x 100px</p>
                            <p className="text-muted">- or -</p>
                            <p className="text-danger">Select Photo</p>
                          </div>
                        </StyledSection>
                      )}
                    </Dropzone> */}
                  </div>
                </Tab>
                <Tab eventKey="profile" title="Profile Picture">
                  <div className="mt-4">
                    <p className="m-0 p-0">
                      <strong>Upload Profile Picture</strong>
                    </p>
                    <small className="text-muted">
                      After you accept an invitation to connect with a Hiring
                      Company, your identity is then revealed. You have the
                      option to display a real picture of yourself by uploading
                      an image here. If you opt to skip this, your Anonymous
                      Avatar will remain there instead.
                    </small>
                    {this.state.profilePhoto !== "" && (
                      <ProfilePhoto src={this.state.profilePhoto} />
                    )}
                    <Dropzone onDrop={this.uploadFile}>
                      {({ getRootProps, getInputProps }) => (
                        <StyledSection>
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <p className="text-muted">Drag a photo here</p>
                            <p className="text-muted">Size: 80px x 80px</p>

                            <p className="text-muted">- or -</p>
                            <p className="text-danger">Select Photo</p>
                          </div>
                        </StyledSection>
                      )}
                    </Dropzone>
                  </div>
                  <div className="pb-5 mr-5 mt-3">
                    <Button
                      onClick={this.handleRemoveAvatar}
                      variant="outline-primary"
                      className="float-left"
                    >
                      Remove
                    </Button>

                    <Link to="/getstarted">
                      <Button className="float-right">Save</Button>
                    </Link>
                  </div>
                </Tab>
              </Tabs>
              {/* <div className="pb-5 mr-5 mt-3">
                <Button
                  onClick={this.handleRemoveAvatar}
                  variant="outline-primary"
                  className="float-left"
                >
                  Remove
                </Button>

                <Link to="/getstarted">
                  <Button className="float-right">Save</Button>
                </Link>
              </div> */}
            </SettingsContainer>
          </Col>
        </Row>
      </Container>
    );
  }
}

const SettingsContainer = styled.div`
  background: white;
  border: 3px solid #f5f5f5;
  flex: 1;
  margin-top: 20px;
  padding: 40px;
`;

const FeedbackEmojisContainer = styled.div`
  img {
    margin: 10px;
    opacity: 0.3;
  }
  img:hover {
    opacity: 1;
    cursor: pointer;
  }
`;
const SettingsHeading = styled.div`
  border-bottom: 1px solid #f5f5f5;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
`;
const SettingsTitle = styled.p`
  font-size: 16px;
  font-weight: bold;
`;

const StyledSection = styled.section`
  background: #f5f5f5;
  display: flex;
  justify-content: center;
  min-height: 250px;
  cursor: pointer;
  margin-top: 15px;
  p {
    text-align: center;
  }
  div {
    align-self: center;
  }
`;

const ProfilePhoto = styled.img`
  display: block;
  width: 90px;
  height: 90px;
  border-radius: 50px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const CoverPhoto = styled.img`
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 200px;
`;

const AvatarsContainer = styled.div`
  img {
    margin: 10px;
  }
`;

export default ProfilePhotoScreen;
